import { HALFTONE_IMG_SELECTOR, HalftoneImageScene } from './scenes/HalftoneImageScene'

const loadHalftoneImageScene = async (images) => {
  const scene = new HalftoneImageScene
  await scene.init({ images: images })
  scene.render()

  return scene
}

loadHalftoneImageScene(Array.from(document.querySelectorAll(HALFTONE_IMG_SELECTOR)))
  .then((scene) => {
    window.HalftoneImageScene = scene
  })
  .catch(err => {
    document.querySelectorAll('img[data-halftone-image]').forEach(img => {
      img.src = img.dataset.src
      delete img.dataset.src
    })
    console.error(err)
  })
