import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js'
import { HalftonePass } from '../three/HalftonePass.js'
import * as THREE from 'three'
import { debounce } from 'lodash-es'

export const HALFTONE_IMG_SELECTOR = 'img[data-halftone-image]'

export class HalftoneImageScene {
  async init({ images }) {
    this.canvas = document.createElement('canvas')

    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: false,
      alpha: true,
    })

    this.renderer.outputEncoding = THREE.sRGBEncoding
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.composer = new EffectComposer(this.renderer)

    this.textureLoader = new THREE.TextureLoader()
    this.renderedImages = {}

    this.params = {
      shape: 1,
      radius: 4 * window.devicePixelRatio,
      rotateR: Math.PI * 0.25,
      scatter: 0,
      blending: 1,
      blendingMode: 1,
      greyscale: false,
      disable: false,
      renderwhite: false
    }

    this.halftonePass = new HalftonePass(1, 1, this.params)

    window.addEventListener('resize', debounce(this.render.bind(this), 100))

    await this.update(images)

    return this
  }

  prepareImages(imageElements) {
    const images = imageElements.map(el => ({
      $el: el,
      src: el.dataset.src,
      renderWhite: el.dataset.halftoneImage !== '',
    }))

    images.forEach((image) => {
      image.$el.classList.add('opacity-0', 'transition-opacity', 'duration-500')
    })

    return images
  }

  async update(images) {
    if (!images) {
      return this
    }

    this.images = this.prepareImages(images)
    this.textures = await this.loadTextures()
    this.scenes = await this.setupScenes()

    return this
  }

  async updateAndRender(d) {
    const images = Array.from(d.querySelectorAll(HALFTONE_IMG_SELECTOR))
    await this.update(images)
    this.render()
  }

  render() {
    for (let index in this.scenes) {
      const scene = this.scenes[index]
      const textureWidth = scene.image.$el.width
      const textureHeight = scene.image.$el.height
      const key = `${scene.image.$el.dataset.src}_${textureWidth}_${textureHeight}`

      if (!this.renderedImages[key]) {
        console.log('Render half-tone image ' + key)
        this.renderer.clear(true, true)
        this.renderer.setSize(textureWidth, textureHeight, false)
        this.composer.setSize(textureWidth, textureHeight)
        this.halftonePass.setSize(textureWidth, textureHeight)
        this.halftonePass.setRenderWhite(scene.image.renderWhite)

        this.composer.passes = []
        this.composer.addPass(new RenderPass(scene.scene, scene.camera))
        this.composer.addPass(this.halftonePass)
        this.composer.render()

        this.renderedImages[key] = this.renderer.domElement.toDataURL('image/jpeg', 0.90)
      } else {
        console.log(`Use cached half-tone image ${key}`)
      }

      scene.image.$el.setAttribute('src', this.renderedImages[key])
      scene.image.$el.classList.remove('opacity-0')
    }
  }

  loadTextures() {
    const textures = {}

    const images = this.images.map((image) => {
      // Return early if texture has been loaded before
      if (textures[image.src]) {
        return Promise.resolve(image.src)
      }

      return new Promise((resolve, reject) => {
        this.textureLoader.load(
          image.src,
          (texture) => {
            textures[image.src] = texture
            resolve(image.src)
          },
          undefined,
          (err) => reject(err)
        )
      })
    })

    return Promise.all(images)
      .then(() => textures)
      .catch(err => {
        console.log(err)
        return {}
      })
  }

  async setupScenes() {
    const scenes = {}

    for (let image of this.images) {
      const texture = this.textures[image.src]
      const camera = new THREE.OrthographicCamera(-1,1,1,-1,-1,1)
      const mesh = new THREE.Mesh(
        new THREE.PlaneGeometry(2, 2),
        new THREE.MeshBasicMaterial({
          map: texture,
        })
      )

      const scene = new THREE.Scene()
      scene.add(mesh)
      scenes[image.src] = { scene, camera, image, texture }
    }

    return scenes
  }
}
